import React, { memo } from 'react';

import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';

import { sendMetrikaEvent } from 'entities/metrika';

import Link from 'next/link';

import s from './Footer.module.scss';

const Footer = () => (
  <footer className="Footer">
    <div className={s.Footer__logo}>SI OPTICS</div>
    <div className={s['Footer-content']}>
      <div className={s.Footer__sections}>
        <div className={s['Footer__sections-block']}>
          <Link href="/rims" className={s.Footer__section}>
            Оправы
          </Link>
          <Link href="/sun" className={s.Footer__section}>
            Солнце
          </Link>
          <Link href="/lens" className={s.Footer__section}>
            Линзы
          </Link>
        </div>
        <div className={s['Footer__sections-block']}>
          <Link href="/about" className={s.Footer__section}>
            О нас
          </Link>
          <Link href="/shops" className={s.Footer__section}>
            Магазин
          </Link>
          <Link href="/privacy.pdf" className={s.Footer__section}>
            Политика
            <br />
            конфиденциальности
          </Link>
        </div>
      </div>
      <div className={s.Footer__contacts}>
        <address className={s['Footer__contacts-block']}>
          <a
            href="tel:+79251854188"
            aria-label="Телефон"
            onClick={() => sendMetrikaEvent('callClick')}
          >
            <LocalPhoneIcon fontSize="large" /> +7 991 177 3386
          </a>
        </address>
        <address>
          <ul className={s['Footer__contacts-block']}>
            <li>
              <a
                href="https://wa.me/79251854188"
                aria-label="WhatsApp"
                onClick={() => sendMetrikaEvent('goToWhatsapp')}
              >
                <WhatsAppIcon fontSize="large" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/si_optics/"
                aria-label="Instagram"
                onClick={() => sendMetrikaEvent('goToInst')}
              >
                <InstagramIcon fontSize="large" />
              </a>
            </li>
            <li>
              <a
                href="https://t.me/si_optics"
                aria-label="Telegram"
                onClick={() => sendMetrikaEvent('goToTelegram')}
              >
                <TelegramIcon fontSize="large" />
              </a>
            </li>
          </ul>
        </address>
      </div>
    </div>
    <div className={s.Footer__copyright}>&copy; sioptics.ru, {new Date().getFullYear()}</div>
  </footer>
);

const MemoFooter = memo(Footer);

export { MemoFooter as Footer };
